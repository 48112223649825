export const GOLD_FAQS = [
  {
    id: 0,
    question: 'What is a Bond?',
    answer:
      'Bonds are a type of loan instrument, these are debt securities issued by the Government, private, and public corporations. Companies issue corporate bonds to raise money for a variety of purposes, such as building a new plant, purchasing equipment, or growing the business.',
  },
  {
    id: 1,
    question: 'What is the difference between Coupon & Yield?',
    answer:
      'Coupon rate of a bond is fixed for the complete tenure and does not change, yield changes depending on when and what price an investor is purchasing the bond.',
  },
  {
    id: 2,
    question: 'What do you mean by Senior secured Bonds?',
    answer:
      'Senior secured bond holders have the highest priority for repayment in case of default or bankruptcy proceedings. They are typically paid back before other creditors and equity holders.',
  },
  {
    id: 3,
    question: 'Do I need a demat account to Invest in bonds?',
    answer:
      'Yes, bonds are available in a dematerialized format, hence you need to have a demat account to hold them.',
  },
  {
    id: 4,
    question: 'How are bonds taxed?',
    answer:
      'Bonds are taxed under Income from other source (IFOS) method according to your tax slab.',
  },
  {
    id: 5,
    question: 'Is TDS deducted in Bonds?',
    answer: 'Yes, there is a 10% TDS deduction on the interest component.',
  },
  {
    id: 6,
    question: 'How will repayment occur?',
    answer:
      "Tap Invest does not play a role in handling the repayments, the repayments happen directly from the issuer company's end, directly in your bank account according to the payout schedule.",
  },
];

export const GOLD_EVENTS = {
  GOLD: 'Clicked_Gold_tab',
  BUY_GOLD: 'Clicked_Gold_buy_Detail_page',
  SELL_GOLD: 'Clicked_Gold_Sell_Detail_page',
  SELECT_CLIENT: 'Gold_sell_select_client',
  GENERATE_PAYMENT_LINK: 'Gold_Generate_Payment_Link',
  ENTER_GOLD_AMOUNT: 'Gold_Enter_Amount',

  // BUY_GOLD_SUCCESS: 'BUY_GOLD_SUCCESS',
  // SELL_GOLD_SUCCESS: 'SELL_GOLD_SUCCESS',
  // BUY_GOLD_FAILURE: 'BUY_FAILURE',
  // IN_RUPPEES_GOLD: 'IN_RUPPEES_GOLD',
  // IN_GRAMS_GOLD: 'IN_GRAMS_GOLD',
  // USER_GOLD_SELECT: 'USER_GOLD_SELECT',
};
