import React, { useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { useNavigate, useParams } from 'react-router-dom';
import {
  postWithdrawMoney,
  useClientBank,
  useClientDetails,
  useClientInvestments,
  useClientStats,
  useClientTaxReportStatus,
} from './useClientDetails';
import { getRupee } from '../../utils/numbers';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import Skeleton from '@mui/material/Skeleton';
import ClientInvestmentsTable from './ClientInvestmentsTable';
import TableSkeleton from '../../components/TableSkeleton';
import { useDelay } from '../../hooks/useDelay';
import { useClientVirtualAccount } from '../Clients/useClient';
import { debounce } from '../../utils/debounce';
import { SearchOutlined } from '@mui/icons-material';
import Input from '../../components/Fields/Input';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { NetworkManager } from '../../services';
import { useDownloadCsv } from '../../hooks/useDownloadCsv';
import ReinvestModal from '../../components/organisms/ReinvestModal/ReinvestModal';
import { getCreateWalletPaymentLink } from '../../hooks/useGetPartnerProfile';
import { Button, CircularProgress, DialogContent, DialogTitle } from '@mui/material';
import { AddCircleIcon, CloseOutlinedIcon, InfoIcon } from '../../assets/mui';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import toast from 'react-hot-toast';
import { CLIENT_DETAIL_EVENTS } from './constants';
import { PATH } from '../../services/NetworkManager';
import { useAnalytics } from '../../hooks/useAnalytics';
import CallMadeIcon from '@mui/icons-material/CallMade';

interface ClientDetailProps {}

const ClientDetail: React.FC<ClientDetailProps> = () => {
  const [open, setOpen] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [walletDetailsOpen, setWalletDetailsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');
  const [addWalletOpen, setAddWalletOpen] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawWalletOpen, setWithdrawWalletOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [userPaymentLink, setUserPaymentLink] = useState('');
  const { clientId } = useParams();
  const { data, isLoading: dataLoading } = useClientDetails(clientId);
  const {
    data: statsData,
    isLoading: statsLoading,
    refetch: statsRefetch,
  } = useClientStats(clientId);
  const { data: bankData, isLoading: isBankLoading } = useClientBank(clientId);
  const { data: investmentsData, isLoading: investmentsLoading } = useClientInvestments(clientId, {
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
  });
  const { data: virtualAccountData, isLoading: virtualAccountLoading } =
    useClientVirtualAccount(clientId);

  const { track } = useAnalytics();

  const { csvLoading, downloadCsv } = useDownloadCsv({
    path: NetworkManager.PATH.csvClientInvestment.replace('{{clientId}}', clientId),
    filename: 'client_investment.csv',
  });

  const { data: taxReportStatus } = useClientTaxReportStatus(clientId);

  const { alTaxReportsGenerated, idTaxReportGenerated } = taxReportStatus?.data || {};

  const getTaxReportPath = () => {
    let pathNames = '';

    if (alTaxReportsGenerated) {
      pathNames = pathNames + '&financeType=ASSET_LEASING';
    }

    if (idTaxReportGenerated && alTaxReportsGenerated) {
      pathNames = pathNames + ',INVOICE_DISCOUNTING';
    } else if (!alTaxReportsGenerated && idTaxReportGenerated) {
      pathNames = pathNames + '&financeType=INVOICE_DISCOUNTING';
    }

    return `${PATH.taxReportDownload
      .replace('{{clientId}}', clientId)
      .replace('{{year}}', '2024')}${pathNames}`;
  };

  const { csvLoading: taxReportLoading, downloadCsv: downloadTaxReport } = useDownloadCsv({
    path: getTaxReportPath(),
    filename:
      alTaxReportsGenerated && idTaxReportGenerated
        ? 'tax_report_FY24.zip'
        : 'tax_report_FY24.xlsx',
  });

  const isReady = useDelay(dataLoading, 200);
  const isStatsReady = useDelay(statsLoading, 500);
  const isInvestmentsReady = useDelay(investmentsLoading, 500);

  const { aadhar: aadhaar, email, name, pan, phone, familyName, clientPublicId } = data?.data || {};

  const { activeInvestment, totalInvestment, totalPayout, walletBalance } = statsData?.data || {};

  const { accountNumber, ifscCode } = bankData?.data || {};
  const navigate = useNavigate();
  const {
    accountNumber: walletAccountNumber,
    bankName: walletBankName,
    branchDetails: walletBranchDetails,
    ifscCode: walletIfscCode,
  } = virtualAccountData?.data || {};

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleWalletOpen = () => {
    track({ eventName: CLIENT_DETAIL_EVENTS.Client_View_beneficiary });
    setWalletOpen(true);
  };

  const handleWalletClose = () => setWalletOpen(false);

  const handleWalletDetailsOpen = () => setWalletDetailsOpen(true);

  const handleWalletDetailsClose = () => {
    // setting both the amount fields to empty on close of bank info modal
    setAmount('');
    setWithdrawAmount('');
    setWalletDetailsOpen(false);
  };

  const handlePaymentClose = () => {
    setIsPaymentOpen(false);
    setUserPaymentLink('');
  };

  const handleAddWalletBalanceOpen = () => {
    track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Balance });
    setAddWalletOpen(true);
  };

  const handleAddWalletBalanceClose = () => {
    setAddWalletOpen(false);
    setAmount('');
  };

  const handleWithdrawWalletBalanceOpen = () => {
    track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Withdraw });
    setWithdrawWalletOpen(true);
  };

  const handleWithdrawWalletBalanceClose = () => {
    setWithdrawAmount('');
    setWithdrawWalletOpen(false);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const onlyNumbers = value.replace(/[^0-9]/g, '');
    if (onlyNumbers.length > 7) {
      toast.error('Amount should be less than 1cr', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }
    setAmount(onlyNumbers);
  };

  const handleWithdrawAmountChange = (e) => {
    const value = e.target.value;
    const onlyNumbers = value.replace(/[^0-9]/g, '');
    if (onlyNumbers > walletBalance) {
      toast.error('Entered Amount is more than wallet balance', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }
    setWithdrawAmount(onlyNumbers);
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  // withdraw money from wallet api call
  const handleWithdrawMoney = () => {
    track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Withdraw });
    setWithdrawLoading(true);
    postWithdrawMoney({ clientPublicId: clientId, amount: withdrawAmount })
      .then((res) => {
        toast.success(`${res?.message || 'Withdrawal request initiated successfully'}`, {
          duration: 2000,
          position: 'top-center',
        });
      })
      .catch((err) => {
        toast.error(
          `${err?.response?.data?.message || 'Something went wrong, please try again later'}`,
          {
            duration: 2500,
            position: 'top-center',
          },
        );
      })
      .finally(() => {
        handleWalletDetailsClose();
        handleWithdrawWalletBalanceClose();
        statsRefetch();
        setWithdrawLoading(false);
      });
  };

  const handleGenerateLink = () => {
    track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Generate_Link });
    getCreateWalletPaymentLink({
      clientPublicId: clientId,
      amount: amount,
    })
      .then((res) => {
        setUserPaymentLink(res?.data);
        setIsPaymentOpen(true);
      })
      .catch((err) => {
        toast.error(
          `${err?.response?.data?.message || 'Something went wrong, please try again later'}`,
          {
            duration: 2500,
            position: 'top-center',
          },
        );
      });
  };

  const handleChangeSort = (e, columnId) => {
    if (columnId == orderBy) {
      setOrder(order == 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  const copyText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.):
    Name: ${name}
    Bank Name: ${walletBankName}
    Branch Name: ${walletBranchDetails}
    Account Number: ${walletAccountNumber}
    IFSC Code: ${walletIfscCode}`;

  const waText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.): %0a
    Name: ${name} %0a
    Bank Name: ${walletBankName} %0a
    Branch Name: ${walletBranchDetails} %0a
    Account Number: ${walletAccountNumber} %0a
    IFSC Code: ${walletIfscCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debounceHandleSearch = debounce(handleSearch, 500);

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="my-6 max-w-7xl mx-auto">
      {/* Heading */}
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={handleBackClick}
            className="bg-green-700/10 flex items-center justify-center p-1.5 h-9 w-9 rounded-full text-green-700"
          >
            <ArrowBack fontSize="small" />
          </button>
          <h1 className="text-lg md:text-xl font-semibold">Client Overview</h1>
        </div>
        {/* TODO: Uncomment when Create Order flow is ready */}
        {/* <button
                    type="button"
                    className="bg-green-700 flex items-center gap-2 text-sm disabled:bg-green-700/80 text-white rounded-xl font-bold h-10 w-fit px-4"
                >
                    <AddCircle fontSize="small" />
                    Create New Order
                </button> */}
        <div className="flex gap-4">
          <button
            onClick={() => {
              handleWalletOpen();
              track({ eventName: CLIENT_DETAIL_EVENTS.Client_View_beneficiary });
            }}
            type="button"
            className="bg-white shadow flex items-center gap-2 text-sm text-gray-700 border border-gray-300 rounded-xl font-semibold h-10 w-fit px-4"
          >
            <FilterListRoundedIcon fontSize="small" />
            View Beneficiary Details
          </button>
          <button
            onClick={() => {
              handleWalletDetailsOpen();
              track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Balance });
            }}
            type="button"
            className="bg-green-700 shadow flex items-center gap-2 text-sm text-white border border-green-900 rounded-xl font-semibold h-10 w-fit px-4"
          >
            <AccountBalanceWalletOutlinedIcon fontSize="small" />
            Wallet Balance
          </button>
          {familyName && (
            <button
              onClick={() => {
                navigate(`/family-account/${clientPublicId}`);
              }}
              type="button"
              className="bg-green-700 shadow flex items-center gap-2 text-sm text-white border border-green-900 rounded-xl font-semibold h-10 w-fit px-4"
            >
              Family Account
              <CallMadeIcon fontSize="small" />
            </button>
          )}
        </div>
      </div>

      {/* User Details */}
      <div className="bg-black/[0.04] min-h-20 grid grid-cols-6 gap-6 sm:gap-3 lg:gap-2 rounded-lg px-6 py-4 mt-6">
        {/* Name/PAN */}
        <div className="col-span-6 max-lg:mb-6 lg:col-span-2 flex items-center gap-3">
          {name && (
            <Avatar
              sizes=""
              className="uppercase md:!w-14 md:!h-14 !bg-white/80 !text-slate-600 border border-slate-300"
            >
              {name?.charAt(0)}
            </Avatar>
          )}
          <div>
            <h3 className="text-slate-800 mb-0.5 font-medium">
              {isReady ? name : <Skeleton width={50} />}
            </h3>
            <h4 className="text-stone-500 text-sm font-medium tracking-widest">{pan}</h4>
          </div>
        </div>
        {/* Email ID */}
        <div className="lg:text-right col-span-3 lg:col-span-1">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Email ID</h3>
          <h4 className="text-sm font-medium text-slate-800 break-all">
            {isReady ? email || '-' : <Skeleton width={70} className="ml-auto" />}
          </h4>
        </div>
        {/* Phone */}
        <div className="lg:text-right col-span-3 lg:col-span-1">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Phone No.</h3>
          <h4 className="text-sm font-medium text-slate-800">
            {isReady ? phone || '-' : <Skeleton width={70} className="ml-auto" />}
          </h4>
        </div>
        {/* Aadhaar */}
        <div className="lg:text-right col-span-3 lg:col-span-1">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Aadhaar</h3>
          <h4 className="text-sm font-medium text-slate-800">
            {isReady ? (
              aadhaar ? (
                `**** **** ${aadhaar}`
              ) : (
                '-'
              )
            ) : (
              <Skeleton width={70} className="ml-auto" />
            )}
          </h4>
        </div>
        {/* Bank Details */}
        <div className="lg:text-right col-span-3 lg:col-span-1">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Bank Details</h3>
          <button onClick={handleOpen} className="text-sm font-medium text-green-700 underline">
            Click to view
          </button>
        </div>
      </div>

      {/* Investment Summary */}
      <div className="mt-6 mb-8 md:mb-12 grid grid-cols-2 xl:grid-cols-4 border border-stone-300 rounded-lg">
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">Wallet balance</h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700 tracking-wider">
            {isStatsReady ? getRupee.format(walletBalance || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">
            Active investments
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isStatsReady ? getRupee.format(activeInvestment || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 border-r border-r-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">
            Total investments
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isStatsReady ? getRupee.format(totalInvestment || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">
            Total returns till date
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isStatsReady ? getRupee.format(totalPayout || 0) : <Skeleton width={60} />}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6 mb-4">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ps-11"
        />
        <div className="flex gap-2 items-center">
          <button
            onClick={() => {
              downloadCsv();
              track({ eventName: CLIENT_DETAIL_EVENTS.Client_Overview_Download_Excel });
            }}
            disabled={csvLoading}
            type="button"
            className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
          >
            <DownloadForOfflineOutlinedIcon fontSize="small" />
            Download Excel
          </button>
          {(alTaxReportsGenerated || idTaxReportGenerated) && (
            <button
              type="button"
              className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
              onClick={downloadTaxReport}
              disabled={taxReportLoading}
            >
              <DownloadForOfflineOutlinedIcon fontSize="small" />
              Download Tax Report
            </button>
          )}
        </div>
      </div>
      {/* Client Investments Table */}
      {isInvestmentsReady && !investmentsLoading ? (
        <ClientInvestmentsTable
          data={investmentsData?.data}
          pageSize={pageSize}
          pageNo={pageNo}
          orderBy={orderBy}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeSort={handleChangeSort}
        />
      ) : (
        <TableSkeleton />
      )}

      {/* Bank Details */}
      <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={open}>
        <div className="p-5 border bg-white border-zinc-200 rounded-lg">
          {isBankLoading ? (
            <Skeleton width={80} height={40} />
          ) : (
            <h3 className="text-lg font-semibold mb-4">Bank Details</h3>
          )}
          <div>
            <div>
              <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                Account number
              </h3>
              <p className="text-sm font-medium text-stone-700">{accountNumber || '-'}</p>
            </div>

            <div className="mt-5">
              <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">IFSC Code</h3>
              <p className="text-sm font-medium text-stone-700">{ifscCode || '-'}</p>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Beneficiary Details  */}
      <Dialog maxWidth="sm" fullWidth onClose={handleWalletClose} open={walletOpen}>
        <div className="py-5 border bg-white border-zinc-200 rounded-lg">
          <div className="px-5 border-b border-b-black/10 pb-6">
            {virtualAccountLoading ? (
              <Skeleton width={80} height={40} />
            ) : (
              <h3 className="text-lg font-semibold">Beneficiary Details</h3>
            )}
            <p className="text-slate-600 text-sm mt-1.5">
              Deposit amount into this bank account to update wallet balance for investments.
            </p>
          </div>
          <div className="p-5 flex flex-col gap-3">
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Name:</h4>
              <h3 className="text-sm font-medium">{name}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Bank Name:</h4>
              <h3 className="text-sm font-medium">{walletBankName}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Branch Name:</h4>
              <h3 className="text-sm font-medium">{walletBranchDetails}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Account Number:</h4>
              <h3 className="text-sm font-medium">{walletAccountNumber}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">IFSC Code:</h4>
              <h3 className="text-sm font-medium">{walletIfscCode}</h3>
            </div>
          </div>
          <div className="px-5 pt-6 border-t border-black/10">
            <div className="flex items-center gap-3 justify-end">
              <a
                href={`whatsapp://send?text=${waText}`}
                target="_blank"
                className="bg-white text-slate-600 border border-black/10 shadow flex items-center gap-2 text-sm rounded-xl font-semibold px-4 h-10"
              >
                Share via WhatsApp
              </a>
              <button
                onClick={copyToClipboard}
                className="bg-green-700 text-white text-sm font-semibold shadow h-10 px-4 rounded-xl"
              >
                Copy All
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Wallet Details Open */}
      <Dialog maxWidth="sm" fullWidth onClose={handleWalletDetailsClose} open={walletDetailsOpen}>
        <div className="py-5 border bg-white border-zinc-200 rounded-lg">
          <div className="px-5 border-b border-b-black/10 pb-6 flex justify-between">
            <div>
              {virtualAccountLoading ? (
                <Skeleton width={80} height={40} />
              ) : (
                <h3 className="text-lg font-semibold">Wallet Details</h3>
              )}
              <p className="text-slate-600 text-sm mt-1.5">
                Your client needs to add money to the following beneficiary, to make investments.
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={handleWalletDetailsClose}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
          <div className="p-5 flex flex-col gap-3">
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Current Balance:</h4>
              <h3 className="text-sm font-medium text-green-700 tracking-wider">
                {isStatsReady ? getRupee.format(walletBalance || 0) : <Skeleton width={60} />}
              </h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Name:</h4>
              <h3 className="text-sm font-medium">{name}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Bank Name:</h4>
              <h3 className="text-sm font-medium">{walletBankName}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Branch Name:</h4>
              <h3 className="text-sm font-medium">{walletBranchDetails}</h3>
            </div>
            <div className="flex items-center">
              <h4 className="text-sm text-gray-600 w-36">Account Number:</h4>
              <h3 className="text-sm font-medium">{walletAccountNumber}</h3>
            </div>
          </div>
          <div className="px-5 pt-6 border-t border-black/10">
            <div className="flex items-center gap-3 justify-end">
              <Button
                className="!bg-white !text-slate-600 !border !border-black/10 !shadow !flex !items-center !text-sm !rounded-xl !font-semibold !px-4 !h-10"
                startIcon={<PlayForWorkIcon />}
                onClick={() => {
                  handleWithdrawWalletBalanceOpen();
                }}
              >
                Withdraw Money
              </Button>
              <Button
                onClick={() => {
                  handleAddWalletBalanceOpen();
                  track({ eventName: CLIENT_DETAIL_EVENTS.Client_Wallet_Balance });
                }}
                className="!bg-green-700 !text-white !text-sm !font-semibold !shadow !h-10 !px-4 !rounded-xl"
                startIcon={<AddCircleIcon />}
              >
                Add Money
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Add Money in wallet Modal */}
      <Dialog maxWidth="md" fullWidth onClose={handleAddWalletBalanceClose} open={addWalletOpen}>
        <div className="py-5 border bg-white border-zinc-200 border-b-0">
          <div className="px-5 border-b border-b-black/10 pb-6 flex justify-between">
            <div>
              {virtualAccountLoading ? (
                <Skeleton width={80} height={40} />
              ) : (
                <h3 className="text-lg font-semibold">Recharge your client’s wallet</h3>
              )}
              <p className="text-slate-600 text-sm mt-1.5">
                A payment link will be created and sent to your client, allowing them to add money
                to their wallet
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={() => handleAddWalletBalanceClose()}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
          <div className="flex justify-between px-10 py-8 items-center">
            <div className="flex flex-col gap-5">
              <h2 className="text-gray-700 font-medium text-lg">Your client’s details</h2>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Current Balance</h4>
                <h3 className="text-base font-normal text-green-700 opacity-80 tracking-wider">
                  {isStatsReady ? getRupee.format(walletBalance || 0) : <Skeleton width={60} />}
                </h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Pan Number</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{pan}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Phone Number</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{phone}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Email ID</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{email}</h3>
              </div>
            </div>

            {/* input */}
            <div className="flex flex-col border rounded-md border-stone-300 h-fit w-2/5 ">
              <h2 className="text-gray-700 font-semibold text-sm mx-5 my-3">Enter Amount</h2>

              <div className="relative w-full mb-6 ml-3 ">
                <span className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-500">
                  ₹
                </span>
                <input
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="1,00,000"
                  className="ml-4 text-lg font-medium text-stone-800 outline-none"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 py-6 border-t border-black/10">
          <div className="flex items-center gap-3 justify-end">
            <button
              onClick={handleGenerateLink}
              className={`${
                Number(amount) > 0 ? 'bg-green-700' : 'bg-gray-300 cursor-not-allowed'
              } text-white text-sm font-semibold h-[2.65rem] px-4 rounded-xl `}
              disabled={!amount}
            >
              Generate Link
            </button>
          </div>
        </div>
      </Dialog>

      {/* Withdraw Money from wallet Modal */}
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={handleWithdrawWalletBalanceClose}
        open={withdrawWalletOpen}
      >
        <div className="py-5 border bg-white border-zinc-200 border-b-0">
          <div className="px-5 border-b border-b-black/10 pb-6 flex justify-between">
            <div>
              {virtualAccountLoading ? (
                <Skeleton width={80} height={40} />
              ) : (
                <h3 className="text-lg font-semibold">Withdraw Money</h3>
              )}
              <p className="text-slate-600 text-sm mt-1.5">
                Enter the amount below to proceed withdrawing money from your client's wallet
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={handleWithdrawWalletBalanceClose}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
          <div className="flex justify-between px-10 py-8 items-center">
            <div className="flex flex-col gap-5">
              <h2 className="text-gray-700 font-medium text-lg">Your client’s details</h2>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Current Balance</h4>
                <h3 className="text-base font-normal text-green-700 opacity-80 tracking-wider">
                  {isStatsReady ? getRupee.format(walletBalance || 0) : <Skeleton width={60} />}
                </h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Pan Number</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{pan}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Phone Number</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{phone}</h3>
              </div>
              <div className="flex items-center">
                <h4 className="text-md text-gray-700 w-36 opacity-40">Email ID</h4>
                <h3 className="text-base font-normal text-stone-800 opacity-80">{email}</h3>
              </div>
            </div>

            {/* input */}
            <div className="flex flex-col border rounded-md border-stone-300 h-fit w-2/5 ">
              <h2 className="text-gray-700 font-semibold text-sm mx-5 my-3">Enter Amount</h2>

              <div className="relative w-full mb-6 ml-3 ">
                <span className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-500">
                  ₹
                </span>
                <input
                  type="text"
                  value={withdrawAmount}
                  onChange={handleWithdrawAmountChange}
                  placeholder="10,000"
                  className="ml-4 text-lg font-medium text-stone-800 outline-none"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 py-6 border-t border-black/10">
          <div className="flex items-center gap-3 justify-end">
            <button
              onClick={handleWithdrawWalletBalanceClose}
              className={`border-slate-200 border bg-white text-sm font-semibold h-[2.65rem] px-4 rounded-xl text-slate-600 `}
            >
              Dismiss
            </button>
            <Button
              onClick={() => handleWithdrawMoney()}
              className={`${
                Number(withdrawAmount) > 0 && !withdrawLoading
                  ? '!bg-green-700'
                  : '!bg-gray-300 !cursor-not-allowed'
              } !text-white !text-sm !font-semibold !h-[2.65rem] !px-4 !rounded-xl `}
              disabled={!withdrawAmount || withdrawLoading}
              endIcon={withdrawLoading && <CircularProgress size={20} color="success" />}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Dialog>

      {/* Client Generate Payment Link */}
      <Dialog open={isPaymentOpen} maxWidth="sm" fullWidth>
        <div className="flex bg-amber-100 p-2.5 items-center gap-2 ">
          <InfoIcon
            className="text-amber-700"
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <h4 className="text-sm text-amber-700 font-medium">
            This link is valid only for 6 hours
          </h4>
        </div>
        <DialogTitle>
          <div className="px-5 flex justify-between">
            <div>
              <h3 className="text-lg font-semibold">Link Generated</h3>
              <p className="text-slate-600 text-sm mt-1.5">
                Copy link to receive money in the client’s wallet
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={() => handlePaymentClose()}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="py-5bg-white rounded-lg">
            <div className="p-5 pb-3 flex gap-3">
              <div
                className="flex flex-col justify-between gap-[6px]  flex-1"
                style={{
                  padding: '14px 16px 16px 0px',
                  borderRight: '1px solid #f0f0f0',
                }}
              >
                <h4 className="text-sm text-stone-400 ">Wallet top-up Amount </h4>
                <h3 className="text-base font-medium text-green-700 tracking-wider">₹{amount}</h3>
              </div>
              <div
                className="flex flex-col justify-between gap-[6px] flex-1"
                style={{ padding: '14px 16px 16px 24px' }}
              >
                <h4 className="text-sm text-stone-400">Current Balance</h4>
                <h3 className="text-base font-medium tracking-wider">
                  {getRupee.format(walletBalance || 0)}
                </h3>
              </div>
            </div>
            <div className="px-5 pt-6 pb-3">
              <div className="flex flex-col gap-[6px]">
                <h4 className="text-sm text-gray-600">Share link with your client</h4>
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={`${userPaymentLink || 'loading link...'}`}
                    readOnly
                    className="flex-grow border border-gray-300 rounded-l-xl p-2"
                  />
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(userPaymentLink);
                      track({ eventName: CLIENT_DETAIL_EVENTS.Client_Copy_url });
                      toast.success('Link copied to clipboard', {
                        duration: 2000,
                        position: 'top-center',
                      });
                    }}
                    className="bg-green-700 text-white text-sm font-semibold h-[2.65rem] px-4 rounded-r-xl "
                  >
                    Copy Url
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ReinvestModal />
    </div>
  );
};

ClientDetail.displayName = 'ClientDetail';

export default ClientDetail;
