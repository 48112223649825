import React, { useCallback, useEffect, useState } from 'react';
import LendboxIcon from '../../../assets/lendbox.png';
import { ReactComponent as AwardSvg } from '../../../assets/svg/award.svg';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { getRupeeWithDecimal } from '../../../utils/numbers';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { getHistoricalMetalData, useMetalTransaction } from '../useDealDetails';
import { baseColors } from '../../../assets/theme';
import { LineChart, areaElementClasses, useDrawingArea } from '@mui/x-charts';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import { TimerComponent } from './components/TimerComponent';
import { TransactionTile } from './components/TransactionTile';
import LoadingDots from '../../../components/molecules/LoadingDotsProps';
dayjs.extend(utc);

const getDateRange = (view) => {
  const today = dayjs(); // Use Day.js to handle dates more easily
  let fromDate;

  switch (view) {
    case 'ONE_WEEK':
      fromDate = today.subtract(1, 'week');
      break;
    case 'ONE_MONTH':
      fromDate = today.subtract(1, 'month');
      break;
    case 'SIX_MONTHS':
      fromDate = today.subtract(6, 'month');
      break;
    case 'ONE_YEAR':
      fromDate = today.subtract(1, 'year');
      break;
    case 'FIVE_YEARS':
      fromDate = today.subtract(5, 'year');
      break;
    default:
      fromDate = today.subtract(1, 'week'); // Default to one week if no match
      break;
  }

  return {
    from: fromDate.format('YYYY-MM-DD'),
    to: today.format('YYYY-MM-DD'),
  };
};

function ColorPalette({ id }) {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
      >
        <stop offset={0} stopColor="#FFFFFF" stopOpacity={1} />
        <stop offset={0.6} stopColor="#D9770610" stopOpacity={1} />
        <stop offset={1} stopColor="#D9770610" stopOpacity={1} />
      </linearGradient>
    </defs>
  );
}

interface MetalItemProps {
  blockid: string;
  goldBuyPrice: string | number;
  goldSellPrice: string | number;
  silverBuyPrice: string | number;
  silverSellPrice: string | number;
  time: string | number | Date;
}

const ViewDetails = ({
  metalLivePrice,
  metalLivePriceLoading,
  transactionList,
  transactionLoading,
  transactionRefetch,
}) => {
  const [currentHistoricalDataView, setCurrentHistoricalDataView] = useState('ONE_WEEK');
  const [dateRange, setDateRange] = useState(getDateRange(currentHistoricalDataView));
  const [transactionType, setTransactionType] = useState('all');
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [historicalMetalData, setHistoricalMetalData] = useState<any>(null);
  const [historicalMetalDataLoading, setHistoricalMetalDataLoading] = useState(false);

  useEffect(() => {
    setDateRange(getDateRange(currentHistoricalDataView));
  }, [currentHistoricalDataView]);

  const { from, to } = dateRange;

  const fetchHistoricalData = useCallback(() => {
    setHistoricalMetalDataLoading(true);
    getHistoricalMetalData({ from, to, range: currentHistoricalDataView })
      .then((response) => {
        setHistoricalMetalData(response);
        setHistoricalMetalDataLoading(false);
      })
      .catch(() => {
        setHistoricalMetalDataLoading(false);
      });
  }, [currentHistoricalDataView, from, to]);

  useEffect(() => {
    if (metalLivePrice?.data?.expiresAt) {
      const expiresAtTime = new Date(metalLivePrice?.data?.expiresAt).getTime();
      const currentTime = new Date().getTime();
      const timeLeft = expiresAtTime - currentTime;

      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          fetchHistoricalData();
        }, timeLeft);

        return () => clearTimeout(timer);
      }
    }
  }, [fetchHistoricalData, metalLivePrice?.data?.expiresAt]);

  useEffect(() => {
    if (transactionType === 'buy' || transactionType === 'BUY') {
      setFilteredTransactions(
        transactionList?.data.filter(
          (transaction) => transaction.action === 'buy' || transaction.action === 'BUY',
        ),
      );
    } else if (transactionType === 'sell' || transactionType === 'SELL') {
      setFilteredTransactions(
        transactionList?.data.filter(
          (transaction) => transaction.action === 'sell' || transaction.action === 'SELL',
        ),
      );
    } else {
      setFilteredTransactions(transactionList?.data);
    }
  }, [transactionType, transactionList?.data]);

  useEffect(() => {
    fetchHistoricalData();
  }, [currentHistoricalDataView]);

  useEffect(() => {
    fetchHistoricalData();
    transactionRefetch();
    fetchHistoricalData();
  }, [metalLivePrice?.data?.expiresAt]);

  const handleTransactionTypeChange = (event: SelectChangeEvent<string>) => {
    setTransactionType(event.target.value);
  };

  const changeHistoricalDataView = (val) => {
    setCurrentHistoricalDataView(val);
  };

  return (
    <>
      {/* Description */}
      <div className="border border-amber-700 p-8 rounded-xl bg-[#fbfbf6] relative">
        {/* Repeated Gradient Divs */}
        <div className="absolute top-0 left-0 w-full h-full flex flex-wrap gap-10 overflow-hidden">
          {[...Array(8)].map((_, index) => (
            <div
              key={index}
              className="w-12 h-full rounded-l-xl"
              style={{
                opacity: '0.11',
                background: 'linear-gradient(180deg, #D97706 0%, rgba(251, 251, 246, 0.00) 100%)',
                filter: 'blur(17.5px)',
                transform: 'rotate(-30.369deg)',
              }}
            ></div>
          ))}
        </div>

        <h2 className="font-semibold text-2xl text-amber-700">Earn upto 0.5% per annum</h2>
        <h3 className="text-stone-500 mt-2 text-base">Grow more, withdraw anytime!</h3>
        <div className="bg-stone-200 flex items-center absolute -bottom-6 left-0 rounded-md -z-10 text-stone-500 font-medium text-xs pt-4 px-3 pb-1">
          Powered by Augmont
        </div>
      </div>

      {/* Used from investor */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginTop: '48px',
          // p: '32px',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography color={baseColors.stone[500]} sx={{ fontSize: '12px', fontWeight: 600 }}>
            LIVE BUY PRICE
          </Typography>
          {metalLivePrice?.data?.goldBuyPrice ? (
            <Typography
              color={baseColors.stone[600]}
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {metalLivePriceLoading ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                getRupeeWithDecimal.format(metalLivePrice?.data?.goldBuyPrice)
              )}
              <Typography
                sx={{
                  color: baseColors.stone[400],
                  fontSize: '12px',
                  fontWeight: 400,
                  marginLeft: '4px',
                }}
              >
                /gm
              </Typography>
            </Typography>
          ) : (
            <Skeleton animation="wave" width="100px" />
          )}
        </Box>
        <Box
          sx={{
            fontSize: '10px',
            fontWeight: 600,
            color: baseColors.stone[400],
            display: 'flex',
            alignItems: 'center',
          }}
        >
          NEXT UPDATE IN
          {metalLivePrice?.data?.expiresAt ? (
            <Typography
              sx={{ color: baseColors.stone[700], fontSize: 'inherit', fontWeight: 'inherit' }}
            >
              <TimerComponent expiresAt={metalLivePrice?.data?.expiresAt} />
            </Typography>
          ) : (
            <Skeleton animation="wave" width="50px" sx={{ ml: 1 }} />
          )}
        </Box>
      </Box>
      {historicalMetalData?.data && (
        <Box>
          {historicalMetalData?.data.length > 5 ? (
            <LineChart
              dataset={historicalMetalData?.data?.sort(
                (a, b) => new Date(a?.time).getTime() - new Date(b?.time).getTime(),
              )}
              xAxis={[
                {
                  scaleType: 'point',
                  data: historicalMetalData?.data?.map(
                    (dataItem: MetalItemProps) => dayjs.utc(dataItem?.time)?.format('DD MMM YYYY'),
                    // dataItem?.time,
                  ),
                },
              ]}
              yAxis={[
                {
                  min: historicalMetalData?.meta?.lowestGoldPrice - 500,
                  max: historicalMetalData?.meta?.highestGoldPrice + 500,
                },
              ]}
              // loading={historicalMetalDataLoading}
              series={[
                {
                  dataKey: 'goldBuyPrice',
                  label: 'Gold',
                  color: '#D97706',
                  area: true,
                  valueFormatter: (v, { dataIndex }) => {
                    const { goldBuyPrice } = historicalMetalData?.data?.[dataIndex];
                    return getRupeeWithDecimal.format(goldBuyPrice);
                  },
                },
              ]}
              height={300}
              margin={{ left: 5, right: 5, top: 0, bottom: 30 }}
              grid={{ horizontal: true }}
              sx={{
                [`& .${areaElementClasses.root}`]: {
                  fill: 'url(#swich-color-id-2)',
                },
                '& .MuiChartsAxis-directionY': {
                  display: 'none',
                },
                '& .MuiChartsAxis-directionX': {
                  display: 'none',
                },
                '& .MuiChartsLegend-root': {
                  display: 'none',
                },
              }}
            >
              <ColorPalette id="swich-color-id-2" />
            </LineChart>
          ) : (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'300px'}>
              <Typography color={baseColors.stone[500]} sx={{ fontSize: '12px', fontWeight: 600 }}>
                Not enough data to display chart
              </Typography>
            </Box>
          )}
          <Tabs
            value={currentHistoricalDataView}
            onChange={(e, value) => {
              changeHistoricalDataView(value);
            }}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: baseColors.white,
                height: '100%',
                borderRadius: '8px',
                zIndex: '-1',
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
              borderRadius: '8px',
              padding: '6px 8px',
              background: 'rgba(0, 0, 0, 0.02)',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Tab label={'1W'} value={'ONE_WEEK'} />
            <Tab label={'1M'} value={'ONE_MONTH'} />
            <Tab label={'6M'} value={'SIX_MONTHS'} />
            <Tab label={'1Y'} value={'ONE_YEAR'} />
            <Tab label={'5Y'} value={'FIVE_YEARS'} />
          </Tabs>
          <Box
            display={'grid'}
            gridTemplateColumns={'1fr 1fr 1fr'}
            my={'24px'}
            border={'1px solid #D6D3D1'}
            borderRadius={'8px'}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'6px'} padding={'24px'}>
              <Typography color={baseColors.stone[700]} sx={{ fontSize: '10px', fontWeight: 600 }}>
                HIGHEST
              </Typography>
              {historicalMetalData?.meta?.highestGoldPrice === undefined ||
              historicalMetalData?.meta?.highestGoldPrice === null ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                <Typography color={baseColors.green[800]} display={'flex'}>
                  {getRupeeWithDecimal.format(historicalMetalData?.meta?.highestGoldPrice)}
                </Typography>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'6px'}
              padding={'24px'}
              borderLeft={'1px solid #D6D3D1'}
              borderRight={'1px solid #D6D3D1'}
            >
              <Typography color={baseColors.stone[700]} sx={{ fontSize: '10px', fontWeight: 600 }}>
                LOWEST
              </Typography>
              {historicalMetalData?.meta?.lowestGoldPrice === undefined ||
              historicalMetalData?.meta?.lowestGoldPrice === null ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                <Typography color={baseColors.stone[600]} display={'flex'}>
                  {getRupeeWithDecimal.format(historicalMetalData?.meta?.lowestGoldPrice)}
                </Typography>
              )}
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={'6px'} padding={'24px'}>
              <Typography color={baseColors.stone[700]} sx={{ fontSize: '10px', fontWeight: 600 }}>
                % CHANGE
              </Typography>
              {historicalMetalData?.meta?.goldPricePercentageChange === undefined ||
              historicalMetalData?.meta?.goldPricePercentageChange === null ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                <Typography color={baseColors.stone[600]} display={'flex'}>
                  {historicalMetalData?.meta?.goldPricePercentageChange} %
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <div className="my-8">
        <h3 className="font-semibold text-xl mb-6">Did you know?</h3>
        <div
          className=" flex justify-start items-center text-stone-500 text-sm min-h-12 w-fit align-middle px-3 py-1.5 rounded-xl"
          style={{
            border: '1px solid rgba(217, 119, 6, 0.70)',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
          }}
        >
          <AwardSvg />
          <span className="ml-2">
            Augoment is the Best Gold Seller in India awarded year on year by Bullion Federation
            Global Convention
          </span>
        </div>
      </div>
      {/* TODO: Instructions need to be updated*/}
      <div>
        <h3 className="font-semibold text-xl mb-6">Gold Investment Payment Instructions</h3>
        <ul className="list-disc flex flex-col gap-2 text-stone-500 ml-4">
          <li>
            <b>Wallet Transactions Not Available:</b> The platform does not support gold investments
            using your existing wallet balance.
          </li>
          <li>
            <b>Payment Link for Investment:</b> A secure payment link will be generated for the
            specified investment amount.
          </li>
          <li>
            <b>Sharing the Link:</b> Share the payment link with your chosen investor or client to
            process the payment.
          </li>
          <li>
            <b>Market Price Adjustment:</b> The investment amount will remain the same, but the gold
            value received may change based on the prevailing market price at the time of payment.
          </li>
          <li>
            <b>Investment Success:</b> Once the payment is completed via the link, your gold
            investment will be successfully processed.
          </li>
        </ul>
      </div>
      {/* Transactions */}
      <div className="mt-12">
        <div className="flex items-center">
          <h3 className="font-semibold text-xl ">Your Transactions</h3>
          {/* <div className="flex-grow border-t-2 border-dashed border-stone-400"></div> */}
          <div
            className="flex-grow"
            style={{
              backgroundImage: 'linear-gradient(to right, #A8A29E 33%, rgba(255,255,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '15px 1px',
              backgroundRepeat: 'repeat-x',
              height: '1px',
              opacity: '0.8',
            }}
          ></div>
        </div>
        <div className="flex items-center mt-3">
          <h3 className="text-base">For all transaction history sorted by </h3>

          <Select
            value={transactionType}
            onChange={handleTransactionTypeChange}
            className="ml-2 !rounded-full  bg-green-700 "
            sx={{
              color: 'white',
              '.MuiSvgIcon-root ': {
                fill: 'white !important',
              },
              '& .MuiSelect-select': {
                paddingLeft: 2,
                paddingTop: 0.5,
                paddingBottom: 0.5,
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="buy">Buy</MenuItem>
            <MenuItem value="sell">Sell</MenuItem>
          </Select>
        </div>
        {transactionLoading ? (
          <div className="flex justify-center items-center px-3 py-4 gap-x-3 w-full  my-3">
            <p className="text-stone-400 text-sm font-medium leading-[21px]">
              Loading <LoadingDots />
            </p>
          </div>
        ) : filteredTransactions?.length > 0 ? (
          filteredTransactions
            ?.slice()
            .map((transaction, index) => <TransactionTile key={index} transaction={transaction} />)
        ) : (
          <div className="flex items-center justify-center h-32">
            <Typography color={baseColors.stone[500]} sx={{ fontSize: '12px', fontWeight: 600 }}>
              No transactions found
            </Typography>
          </div>
        )}
      </div>
      {/* Documents */}
      {/* <div className="mt-8">
        <h3 className="font-semibold text-lg mb-6">Documents</h3>
        <div className="flex flex-wrap gap-6">
          {documents &&
            documents.map((document: any) => (
              <a href={document.link} target="_blank" download>
                <div
                  key={document.name}
                  className="border border-stone-200 md:w-96 rounded-lg py-5 px-4 flex justify-between items-center"
                >
                  <div>
                    <h4 className="text-stone-700 font-medium text-sm">{document.name}</h4>
                    <p className="text-stone-500 text-sm">{document.text}</p>
                  </div>
                  <DownloadForOfflineOutlinedIcon className="text-stone-500" />
                </div>
              </a>
            ))}
        </div>
      </div> */}

      {/* FAQs */}
      {/* <div className="mt-8">
        <h3 className="text-stone-700 text-lg font-medium mb-3">Frequently Asked Questions</h3>
        <GoldFaqs />
      </div> */}
    </>
  );
};

export default ViewDetails;
