import * as React from 'react';
import Tables from '../../components/organisms/Tables/Tables';
import { SORTABLE_COLUMNS } from './constants';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CurrencyAmount from '../../components/organisms/Tables/CellRenderer/CurrencyAmount';
import Name from '../../components/organisms/Tables/CellRenderer/Name';

function createData(familyName, numberOfClients, investedAmount, edit, publicId) {
  return {
    familyName,
    numberOfClients,
    investedAmount,
    edit,
    id: publicId, // Use publicId as the row's id
  };
}

const columns = [
  { id: 'familyName', label: 'Family Label', minWidth: 170, align: 'left' },
  { id: 'numberOfClients', label: 'Number of Clients', minWidth: 170, align: 'left' },
  { id: 'investedAmount', label: 'Invested Amt.', minWidth: 170, align: 'left' },
  {
    id: 'edit',
    label: 'Edit',
    minWidth: 50,
    align: 'center',
    isEditable: true,
  },
];

interface ClientTableProps {
  data: {
    currentPage: number;
    items: any[];
    pageSize: number;
    totalItems: 0;
  };
  pageSize: number;
  pageNo: number;
  orderBy: string;
  order: 'asc' | 'desc';
  handleChangePage: (event: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
  handleRowClick: (row: any) => void;
  handleChangeSort: (event: any, columnId: string) => void;
  onEditClick?: (row: any) => void; // Optional prop to handle edit click
  isEditable?: boolean; // Flag to show/hide the edit column
  loading: boolean;
}

const getRowsAndCols = (data, isEditable = true, onEditClick) => {
  const rows = [];
  const items = data || [];

  items.forEach((element) => {
    const familyName = <Name name={element.familyName} />;
    const numberOfClients = element.numberOfClients || 0;
    const investedAmount = <CurrencyAmount amount={element.investedAmount} showZero={true} />;

    const editButton = isEditable ? (
      <IconButton
        aria-label="edit"
        onClick={(event) => {
          event.stopPropagation();
          onEditClick && onEditClick(element);
        }}
        size="small"
      >
        <EditIcon fontSize="small" />
      </IconButton>
    ) : null;

    rows.push(
      createData(familyName, numberOfClients, investedAmount, editButton, element.publicId),
    );
  });

  return rows;
};

const FamilyAccountTable: React.FC<ClientTableProps> = ({
  data,
  pageSize,
  pageNo,
  orderBy,
  order,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRowClick,
  handleChangeSort,
  onEditClick,
  isEditable = true, // Default to true to show the edit column
  loading,
}) => {
  const rows = getRowsAndCols(data, isEditable, onEditClick);
  const totalData = data?.totalItems;

  return (
    <Tables
      totalData={totalData}
      columns={columns}
      sortableColumns={SORTABLE_COLUMNS}
      rows={rows}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleRowClick={handleRowClick}
      handleChangeSort={handleChangeSort}
      rowsPerPage={pageSize}
      page={pageNo}
      orderBy={orderBy}
      order={order}
      loading={loading}
      pagination={false}
    />
  );
};

FamilyAccountTable.displayName = 'FamilyAccountTable';

export default FamilyAccountTable;
